<template>
  <div class="group-content" v-loading="formLoading">
    <el-form
      size="medium"
      :model="detailForm"
      :rules="rules"
      label-position="right"
      label-width="120px"
      class="small-form"
      ref="detailForm"
      @submit.native.prevent
    >
      <div class="modular">
        <p class="modular-title">基础信息</p>
        <div class="modular-content" style="margin-left: 11px">
          <el-form-item label="电子书来源：" prop="source_type">
            <el-select v-model="detailForm.source_type" placeholder="请选择" disabled>
              <el-option
                v-for="item in sourceTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电子书名称：" prop="name">
            <el-input
              type="text"
              v-model="detailForm.name"
              placeholder="请输入名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="电子书封面：" prop="cover">
            <image-wall
              v-model="detailForm.cover"
              :width="150"
              :height="200"
              ratio="3:4"
            >
              <p slot="info" class="img-tips">
                <span class="el-icon-info" />建议尺寸为300*400
              </p>
            </image-wall>
          </el-form-item>

          <el-form-item label="选择分类：" prop="categories">
            <div class="flex">
              <el-select
                multiple
                v-model="detailForm.categories"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in optionList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <!-- 通用添加分类组件 -->
              <AddCategoryButton
                :style="{
                  marginLeft: '12px',
                }"
                :updateList="getCategoryList"
                :getApi="getApi"
              >
              </AddCategoryButton>
            </div>
          </el-form-item>
          <el-form-item
            label="电子书简介类型："
            prop="description_type"
            required
          >
            <el-radio-group v-model="detailForm.description_type">
              <el-radio :label="0">纯文本</el-radio>
              <el-radio :label="1">富文本</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="电子书简介：" prop="description">
            <el-input
              v-if="detailForm.description_type === 0"
              type="textarea"
              :rows="12"
              :maxlength="900"
              show-word-limit
              v-model="detailForm.description"
              placeholder="请输入电子书简介："
            >
            </el-input>
            <tinymce
              v-else
              ref="editorA"
              @choose="handleToolBarClick"
              v-model="detailForm.description"
              show-article-import
            ></tinymce>
          </el-form-item>
          <el-form-item label="作者名称：" prop="teacher_name">
            <el-input
              type="text"
              v-model="detailForm.teacher_name"
              placeholder="请输入作者名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="作者介绍："
            prop="teacher_introduce_type"
            required
          >
            <el-radio-group v-model="detailForm.teacher_introduce_type">
              <el-radio :label="0">纯文本</el-radio>
              <el-radio :label="1">富文本</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="作者介绍：" prop="teacher_introduce">
            <el-input
              v-if="detailForm.teacher_introduce_type === 0"
              type="textarea"
              :rows="12"
              :maxlength="900"
              show-word-limit
              v-model="detailForm.teacher_introduce"
              placeholder="请输入作者介绍"
            >
            </el-input>
            <tinymce
              v-else
              ref="editorB"
              @choose="handleToolBarClick"
              v-model="detailForm.teacher_introduce"
              show-article-import
            ></tinymce>
          </el-form-item>
          <el-form-item label="上传文件：" v-if="detailForm.source_type === 0">
            <el-upload
              action="#"
              :before-remove="beforeRemove"
              :on-remove="handleRemove"
              :http-request="handleUpload"
              :before-upload="beforeUpload"
              :file-list="
                detailForm.file_url
                  ? [{ name: '上传文件', url: detailForm.file_url }]
                  : []
              "
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传word、excel、pdf、jpg、png文件，且不超过150M
              </div>
            </el-upload>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <!-- 富文本插入图片 -->
    <media-selector v-model="showImageList" multiple @select="updateImages" />
    <media-selector
      v-model="showVideoList"
      type="video"
      multiple
      @select="updateVideos"
    />
    <media-selector
      v-model="showAudioList"
      type="audio"
      multiple
      @select="updateAudios"
    />
    <!-- 文章导入 -->
    <import-article v-model="isShowImportDialog" @confirm="getImportDetail" />
    <!-- 导入HTML -->
    <import-html-code v-model="isShowHtmlInput" />
    <FixedActionBar>
      <el-button
        type="primary"
        size="medium"
        @click="formSubmit('detailForm')"
        :loading="saveLoading"
        >保存
      </el-button>
      <el-button size="medium" @click="cancelConfig">取消</el-button>
    </FixedActionBar>
  </div>
</template>
<script>
import {
  categoryList,
  saveCategory,
} from '@/modules/college/api/learn-category-list'
import { getDetail, saveDetail } from '@/modules/college/api/ele-book-list'
import ImageWall from '@/modules/common/components/SingleMediaWall.vue'
import FixedActionBar from '@/base/layout/FixedActionBar'
import SelectMember from '@/modules/common/components/MemberSelector'
import { checkImg } from '@/base/utils/regs'
import AddCategoryButton from '@/base/components/Base/AddCategoryButton.vue'
import { getFileType } from '@/base/utils/tool'
import filesUpload from '@/base/utils/upload4'

// 富文本相关组件
import Tinymce from '@/base/components/Editor/Tinymce'
import MediaSelector from '@/modules/common/components/MediaSelector.vue'
import ImportArticle from '@/base/components/Editor/ImportArticle'
import ImportHtmlCode from '@/base/components/Editor/ImportHtmlCode'
export default {
  components: {
    ImageWall,
    Tinymce,
    SelectMember,
    FixedActionBar,
    MediaSelector,
    ImportArticle,
    ImportHtmlCode,
    AddCategoryButton,
  },
  data() {
    return {
      detailForm: {
        id: this.$route.params.id,
        source_type: 0,
        // 简介类型
        description_type: 0,
        // 老师介绍类型
        teacher_introduce_type: 0,
        name: '',
        teacher_name: '',
        cover: '',
        description: '',
        file_url: '',
        categories: [],
      },
      // 来源类型
      sourceTypeList: [
        {
          id: 0,
          name: '自建',
        },
        {
          id: 1,
          name: '课程库',
        },
        {
          id: 2,
          name: '得到',
        },
        {
          id: 3,
          name: '量子',
        },
        {
          id: 4,
          name: '喜马拉雅',
        },
      ],
      // 配置列表
      optionList: [],
      rules: {
        source_type: [
          { required: true, message: '请选择来源', trigger: 'blur' },
        ],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        teacher_name: [
          { required: true, message: '请输入作者名称', trigger: 'blur' },
        ],
        description: [
          { required: true, message: '请输入简介', trigger: 'blur' },
        ],
        teacher_introduce: [
          { required: true, message: '请输入老师介绍', trigger: 'blur' },
        ],
        // audio_url: [{ required: true, message: '请上传音频', trigger: 'blur' }],
        cover: [{ required: true, message: '请上传音频封面', trigger: 'blur' }],
        categories: [
          { required: true, message: '请选择分类', trigger: 'blur' },
        ],
        avatar: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
      },

      formLoading: false,
      saveLoading: false,

      // 媒体库图片弹窗
      showImageList: false,
      showVideoList: false,
      showAudioList: false,
      isShowImportDialog: false,
      isShowHtmlInput: false,
    }
  },
  methods: {
    beforeRemove(file) {
      /* 不符合条件的文件无法上传，选择后会触发 beforeRemove 和 handleRemove */
      console.log(file)
      if (!this.beforeUpload(file, true)) return
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 清空上传文件
    handleRemove(file, fileList) {
      console.log(file, fileList)
      // this.$set(this.form, 'files', [])
      this.detailForm.file_url = ''
    },
    /**
     * @param file
     * @param silent  是否关闭错误提示
     * */
    beforeUpload(file, silent = false) {
      /* 文件类型判断 */
      const myFileType = getFileType(file?.name)
      const fileTypeSupported = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'jpg',
        'jpeg',
        'png',
      ].includes(myFileType)
      // 大小判断
      const is10M = file.size / 1024 / 1024 < 150
      if (!fileTypeSupported && !silent) {
        this.$message.error('上传文件类型仅支持 word、excel、pdf、jpg、png !')
      } else if (!is10M && !silent) {
        // 大小不匹配
        this.$message.error('上传文件大小不能超过 150MB !')
      }
      // 返回 false 阻断 true 正常上传
      return is10M && fileTypeSupported
    },
    handleUpload(file) {
      /* 上传成功后才将{name: '', url: ''}push到fileList中，会有成功图标 */
      // this.fileList.push({name: file.file.name, url: ''})
      filesUpload({
        configApi: '/admin/admin/media/requestUpload',
        data: file.file,
        progress: (n) => {
          // 更新 el-upload file-list 自带的进度条数值
          file.onProgress({ percent: n })
        },
      })
        .then((res) => {
          this.detailForm.file_url = res.data.url
        })
        .catch(() => {})
    },
    getApi(data) {
      return saveCategory(data)
    },
    // 富文本点击事件
    handleToolBarClick(e) {
      switch (e.type) {
        case 'image':
          this.showImageList = true
          break
        case 'video':
          this.showVideoList = true
          break
        case 'audio':
          this.showAudioList = true
          break
        case 'import':
          this.isShowImportDialog = true
          break
        case 'html':
          this.isShowHtmlInput = true
          break
      }
    },
    // ------富文本相关
    updateImages(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<img src="${item.url}"/>`
        )
      })
    },
    // 获取分类列表
    getCategoryList() {
      categoryList({ tab: 'normal' }).then((res) => {
        console.log(res)
        this.optionList = res.data.list
      })
    },
    updateVideos(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<p><span class="mce-preview-object mce-object-video"
                contenteditable="false"
                data-mce-object="video"
                data-mce-p-controls="controls"
                data-mce-p-controlslist="nodownload"
                data-mce-p-allowfullscreen="true"
                data-mce-p-frameborder="no"
                data-mce-p-scrolling="no"
                data-mce-html=""
                data-mce-p-src="${item.url}">
                <video controls src="${item.url}"></video></span></p>
                <p><br/></p>`
          // `<video poster="${item.config.thumbnail}" controls src="${item.url}"></video>`
        )
      })
    },
    updateAudios(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<p><span class="mce-object-audio"
            contenteditable="false"
            data-mce-object="audio"
            data-mce-p-controls="controls"
            data-mce-p-controlslist="nodownload"
            data-mce-p-frameborder="no"
            data-mce-p-scrolling="no"
            data-mce-html=""
            data-mce-p-src="${item.url}">
            <audio controls src="${item.url}"></audio></span></p>
            <p><br/></p>`
        )
      })
    },
    // 导入公众号文章内容
    getImportDetail(e) {
      // e: {account, content, cover_image, title}

      const newContent = this.form.description + '<p><br/></p>' + e.content
      this.$set(this.form, 'description', newContent)
      this.$refs.editor.$emit('change', newContent)

      if (!this.form.name) this.form.name = e.title
      if (!this.form.cover) this.form.cover = e.cover_image
      if (this.form.images.length < 9) {
        this.form.images.push(e.cover_image)
      }
    },
    // 获取详情
    getDataDetail() {
      this.formLoading = true
      console.log({ id: this.detailForm.id })
      getDetail({ id: this.detailForm.id })
        .then((res) => {
          const { data } = res
          // 分类id
          data.categories = data.categories.map((item) => item.id)
          this.detailForm = data
          this.formLoading = false
        })
        .catch((err) => {
          this.formLoading = false
        })
    },
    // 保存
    formSubmit(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          const data = { ...this.detailForm }
          // 根据id 查找item
          data.categories = this.optionList.filter((item) => {
            return data.categories.includes(item.id)
          })
          this.saveLoading = true

          saveDetail(data)
            .then((res) => {
              this.$message.success(res.msg)

              // this.getDataDetail()
              this.$router.push({ name: 'CollegeEleBookList' })

              this.saveLoading = false
            })
            .catch((err) => {
              this.saveLoading = false
            })
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error')
            isError[0].scrollIntoView({ block: 'center', behavior: 'smooth' })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    // 取消
    cancelConfig() {
      this.$router.push({ name: 'CollegeEleBookList' })
    },
  },
  created() {
    if (this.detailForm.id != 0) {
      this.getDataDetail()
    }
    this.getCategoryList()
  },
}
</script>

<style lang="scss" scoped>
.group-content {
  min-height: 520px;

  .add-host:hover {
    border-color: #409eff;
  }

  .input-item {
    width: 194px;
    text-align: center;
    margin-right: 100px;

    .images {
      width: 194px;
      height: 135px;
      cursor: pointer;
    }

    .upload-btn {
      width: 194px;
      height: 135px;
      border: 1px dashed #d9d9d9;
      .plus-icon {
        font-size: 20px;
        .upload-text {
          font-size: 14px;
          line-height: 30px;
        }
      }
    }

    .upload-btn:hover {
      border-color: #409eff;
    }
  }
  .info-title {
    font-size: 14px;
    line-height: 30px;
  }

  .img-tips {
    margin-top: 8px;
    font-size: 12px;
    color: #c0c4cc;
    line-height: 15px;
  }
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}

.member-list {
  margin-left: 95px;
  width: 400px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  .list-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-bottom: 1px solid #dcdfe6;
    .top-left {
      font-weight: bold;
    }
    .top-right {
      .clear-member {
        padding: 0;
      }
    }
  }
  .list-content {
    min-height: 52px;
    padding: 10px 0px 0 10px;
    .list-item {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

.modular-content + .modular-content {
  margin-top: 22px;
}
</style>
